import { moreFeatures, facCaro, filterUnits, initFacilityTop, initFacilityMap, toTop } from './facilities.js';
import { initReviewsCaro } from './reviewsCaro';
import { initTipsList } from './tips.js';
import { initMobileNav, doubleClick, headerPhone } from './header.js';
import { contactSubmit, initContactMap } from './contact.js';

initMobileNav();
doubleClick.init();
headerPhone.init();

if (document.getElementById('contact_form')) {
	contactSubmit({
		required: ['name', 'email', 'facility', 'message']
	});
}

if(document.querySelector('#inc_reviews_caro')) {
	initReviewsCaro();
}

if(document.querySelector('.tips_list')) {
	initTipsList();
}

if(document.querySelector('#inc_map')) {
	initContactMap();
}

if(document.querySelector('#facility_info')) {
	initFacilityTop();
}

if(document.querySelector('#facility_map')) {
	initFacilityMap();
}

if(document.querySelector('#facility_carousel')) {
	facCaro.init();
}

if(document.querySelector('#facility_features')) {
	moreFeatures.init();
}


if (document.querySelector('.rates_row')) {
	filterUnits();
}

if (document.querySelector('#to_top')) {
	toTop();
}
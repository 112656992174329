/* global Velocity */
/*global google*/
import {getMapsHelper} from './util/mapsHelper.js';
import { AutomatitCarousel } from './automatit_carousel.m';

export const moreFeatures = (function(){
	let my = {};

	function _assignListeners(){
		document.getElementById('view_features').addEventListener('click', (e) => {
			e.currentTarget.style.opacity = 0;
			document.querySelector('#feature_list .feature_list_more').style.display = "block";
		});
	}

	my.init = function(){
		if (document.getElementById('view_features')){
			_assignListeners();
		}
	};

	return my;
})();

export const facCaro = (function(){
	let my = {};

	my.init = function(){
		if(document.getElementById('facility_carousel')){
			AutomatitCarousel({
				element: document.getElementById('facility_carousel'),
				showDots: false,
				useChevrons: true,
				imagesAsBackgrounds: true,
				images: window.facCaroImgs
			});
		}
	};

	return my;
})();

export const filterUnits = () => {

	document.querySelector('#filter_list').addEventListener('click', e => {
		const clickedFilter = e.target.closest('button');
		const noUnits = document.getElementById('no_units');

		if(clickedFilter) {
			let count = 0;

			document.querySelectorAll('#filter_list button').forEach(filter => filter.removeAttribute('active'));
			clickedFilter.setAttribute('active', '');
			noUnits.style.display = 'none';

			document.querySelectorAll('#rates_table .rates_row.filterable').forEach(row => {
				if (clickedFilter.dataset.prop === 'all' || row.dataset.prop === clickedFilter.dataset.prop || row.dataset.climate === clickedFilter.dataset.climate) {
					row.style.display = 'flex';
					count++;
				} else {
					row.style.display = 'none';
				}
			});

			if (count === 0) {
				noUnits.style.display = 'block';
			}
		}
	});
};

const facilityTop = {
	mobileToggle: document.querySelector('#toggle_fac_info'),
	info: document.querySelector('#facility_info'),
	mobileClick: function() {
		this.mobileToggle.toggleAttribute('open');
		this.info.toggleAttribute('open');
	},
	init: function() {
		this.mobileToggle.addEventListener('click', () => this.mobileClick());
		document.querySelector('#rates_header a').addEventListener('click', () => Velocity(document.querySelector('#rates_table_wrapper'), 'scroll'));
		document.querySelector('#facility_title span').addEventListener('click', () => Velocity(document.querySelector('#facility_map'), 'scroll'));
	}
};


export const initFacilityTop = () => facilityTop.init();

const facilityMap = {
	theMap: {
		ele: document.querySelector('#facility_map'),
		instance: null
	},
	init: async function() {
		const mapsHelper = getMapsHelper();

		await mapsHelper.ready();

		this.theMap.instance = mapsHelper.createMap({
			element: this.theMap.ele,
			locationElementSelector: '#facility_map',
			useRichmarker: true,
			markerReducer: el => {
				return {
					lat: el.getAttribute('lat'),
					lng: el.getAttribute('lng'),
					content: 
						`<span class="map_pin">
							<img src="/dist/images/icons/map_pin.svg" alt="" />
						</span>`
				};
			}
		});

		google.maps.event.addListenerOnce(this.theMap.instance, 'idle', () => {
			this.theMap.instance.setZoom(16);
		});
	}
};

export const initFacilityMap = () => facilityMap.init();

export const toTop = () => {
	var footerHeight = document.getElementById('footer');
	window.addEventListener('scroll', function() {
		if(window.scrollY > 50){
			document.getElementById('to_top').classList.add('active');
			document.querySelector('#to_top').style.bottom = `${footerHeight.offsetHeight}px`;
		} else{
			document.getElementById('to_top').classList.remove('active');
		}
	});
	document.querySelector('#to_top').addEventListener('click', () => Velocity(document.querySelector('#container'), 'scroll'));

};
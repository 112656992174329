const mobileNav = {
	toggle: document.querySelector('#toggle_mobile_nav'),
	menu: document.querySelector('#nav_container'),
	btnClick() {
		this.toggle.toggleAttribute('open');
		this.menu.toggleAttribute('open');
	},
	init() {
		this.toggle.addEventListener('click', () => this.btnClick());
	}
};

export const doubleClick = (function(){
	let my = {};

	function _addDoubleClick() {
		[].forEach.call(document.querySelectorAll('.d_click'), (el) => {
			el.addEventListener('click', e => {
				if ('ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) {
					if (!el.hasAttribute('clicked') && window.screen.width > 750) {
						e.preventDefault();
						el.setAttribute('clicked', true);
					}
				}
			});
		});
	}

	my.init = function(){
		_addDoubleClick();
	};

	return my;
})();

export const initMobileNav = () => mobileNav.init();

export const headerPhone = (function(){
	let my = {};

	my.init = function(){
		if(document.querySelector('.header_btn.overlay')){
			document.querySelector('.header_btn.overlay').addEventListener('click', (e) => {
				e.preventDefault();
				document.getElementById('phone_overlay').classList.add('active');
			});
			document.getElementById('phone_overlay').addEventListener('click', () => {
				document.getElementById('phone_overlay').classList.remove('active');
			});
			document.getElementById('phone_content').addEventListener('click', (e) => {
				e.stopImmediatePropagation();
			});
		}
	};

	return my;
})();